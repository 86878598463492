import CountUp from 'react-countup';
import Guthaben from '../data/Guthaben.js';

const Home = () => {

  // Gesamtes Guthaben berechnen
  var guthaben_gesamt = 0;
  Guthaben.forEach(element => {
    guthaben_gesamt += element.euro
  });

  // Seite zurückgeben
  return (
    <div className="money">
      Aktuelles Guthaben
      <CountUp
        start={0}
        end={guthaben_gesamt}
        duration={4.75}
        delay={0}
        suffix="€">
        {({ countUpRef, start }) => (
          <div className="counter">
            <span ref={countUpRef} />
          </div>
        )}
      </CountUp>
    </div>
  );
}

export default Home;