var Guthaben = [
  { "euro": 50,
    "datum": "16. Geburtstag",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 50,
    "datum": "Weihnachten 2021",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 200,
    "datum": "Weihnachten 2022 + Zeugnis",
    "einzahler": "Karsten & Heike"
  },
  { "euro": 50,
    "datum": "17. Geburtstag",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 200,
    "datum": "17. Geburtstag",
    "einzahler": "Karsten & Heike"
  },
  { "euro": 75,
    "datum": "Weihnachten 2022",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 50,
    "datum": "18. Geburtstag",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 100,
    "datum": "Weihnachten 2023",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 50,
    "datum": "Weihnachten 2023",
    "einzahler": "Karsten & Heike"
  },
  { "euro": 50,
    "datum": "19. Geburtstag",
    "einzahler": "Tom & Sabrina"
  },
  { "euro": 250,
    "datum": "19. Geburtstag & Abitur",
    "einzahler": "Karsten & Heike"
  }
];
export default Guthaben;
