import Guthaben from '../data/Guthaben.js';

const Detail = () => {
  // Liste
  let list = Guthaben.map(data => (
    <div className="detailEntry">
      <strong>{data.datum}</strong><br/>
      {data.euro}€ von {data.einzahler}
    </div>
  ));

  // Seite zurückgeben
  return (
    <div>
        <div className="money">
          Guthabenhistorie
        </div>
        {list}
    </div>
  );
}

export default Detail;
