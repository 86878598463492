import { useState } from 'react';
import Header from './Header';
import Home from './Home';
import Detail from './Detail';

const Main = () => {

  // Detailansicht
  const [detail_view, setDetail] = useState(false);
  const handleClick = () =>{
    setDetail(!detail_view);
  }

  // Seite zurückgeben
  return (
    <div className="App" >
      <Header />
      {detail_view ? <Detail /> : <Home /> }
      <button onClick={handleClick}>{detail_view ? "Gesamtsumme anzeigen" : "Details anzeigen" }</button>
    </div>
  );
}

export default Main;