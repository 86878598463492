import logo from '../assets/logo.svg';

const Header = () => {
  // Seite zurückgeben
  return (
    <div>
        <img src={logo} className="App-logo" alt="logo" />
        <p className="header">Kevins<br />
        <span className="small">Virtuelle Geldbörse für den Führerschein</span></p>
        <hr/>
    </div>
  );
}

export default Header;
